{
  "common": {
    "language": "ภาษา",
    "lang": {
      "en": "English",
      "th": "ภาษาไทย",
      "zhHant": "简体中文"
    },
    "menuList": {
      "about": "เกี่ยวกับ",
      "gameTypes": "ประเภทเกม",
      "ourService": "บริการ",
      "partnership": "สนับสนุนโดย",
      "contact": "ติดต่อเรา"
    },
    "welcome": "ยินดีต้อนรับ ",
    "slogan": "เล่นง่าย รวยเร็ว",
    "scroll": "เพิ่มเติม",
    "etc": "ฯลฯ",
    "playGame": "ลองเล่น",
    "detail": "รายละเอียด",
    "return": "กลับ",
    "similarGame": "เกมใกล้เคียง",
    "about": {
      "title": {
        "service": "24ชม"
      },
      "description": {
        "gameTypes": "เกมหลากหลาย",
        "service": "บริการลูกค้า24/7",
        "currency": "สนับสนุนหลากสกุลเงิน",
        "device": "สนับสนุนหลากอุปกรณ์",
        "language": "หลากภาษา"
      }
    },
    "gameType": {
      "hot": "ฮ๊อต",
      "frequent": "เล่นบ่อย",
      "graph": "กราฟ",
      "liveStream": "สตรีมสด",
      "lottery": "ลอตเตอรี่",
      "rng": "RNG",
      "ball": "หวยปิงปอง",
      "stock": "หุ้น",
      "description": {
        "graph": "แนวโน้ม",
        "liveStream": "เลือกไลฟ์สตรีม",
        "lottery": "เกมหวยให้เลือกมากมาย",
        "rng": "เล่นได้ตลอด24ชม",
        "ball": "เกมดังหวยปิงปอง",
        "stock": "หุ้นหลากประเทศให้เลือก"
      }
    },
    "ourService": {
      "title": {
        "api": "ต่อAPI",
        "admin": "ระบบหลังบ้าน",
        "risk": "ควบคุมความเสี่ยง"
      },
      "description": {
        "api-1": "หลายประเภทกระเป๋าให้เลือก ต่อง่ายรวดเร็ว",
        "admin-1": "ควบคุมบัญชี",
        "admin-2": "สันบสนุนรีพอร์ท",
        "admin-3": "บริการลูกค้า24/7",
        "risk-1": "ตั้งค่าเลขดัง",
        "risk-2": "ควบคุมอัตราจ่ายอัตโนมัติ",
        "risk-3": "แจ้งเตือนเมื่อยอดเทิร์นถึงเกณฑ์"
      }
    },
    "gameInfo": {
      "awardPeriod": "ช่วงเวลาเปิดหวย",
      "betType": "ยอดรวมประเภทเกม",
      "bundleType": "เหมาเลข",
      "maxPayout": "อัตราจ่ายสูงสุด",
      "deviceSupport": "สนับสนุนอุปกรณ์",
      "languageSupport": "สนับสนุนภาษา",
      "rule": "กติกา"
    },
    "bundleType": {
      "19d": "19D",
      "box": "ตัวกลับ",
      "combo": "คอมโบ"
    },
    "gameName": {
      "1-0001": "หวยไทย",
      "1-0002": "หวยฮานอย",
      "1-0003": "หวยลาว",
      "1-0005": "หวยมาเล (Magnum 4D)",
      "1-0006": "หวยสิงคโปร์ (4D)",
      "1-0009": "หวยหุ้นไทย (SET)",
      "1-0010": "หวยหุ้นอินเดีย (Sensex)",
      "1-0011": "Star 3",
      "1-0012": "หวยหุ้นญี่ปุ่น (Nikkei225)",
      "1-0013": "หวยหุ้นเกาหลี (KTOP30)",
      "1-0014": "หวยเวียดนาม โฮจิมินห์",
      "1-0015": "หวยเวียดนาม ดานัง",
      "1-0016": "หวยเวียดนาม คั้ญฮหว่า",
      "1-0017": "บิทคอยน์",
      "1-0018": "Yee Kee",
      "2-0002": "หวยปิงปองเม็กซิโก (Melate)",
      "2-0003": "12ราศี",
      "2-0004": "PK10",
      "2-0005": "RG",
      "2-0006": "หวยปิงปองเม็กซิโก (Melate Retro)",
      "2-0007": "หวยปิงปองวินวิน",
      "2-0008": "คีโน่"
    },
    "awardPeriod": {
      "1-0001": "ทุกวันที่1ของ16ของเดือน",
      "1-0002": "ทุกวัน",
      "1-0003": "ทุกวันจันทร์ วันพุธและวันศุกร์",
      "1-0005": "ทุกวันพุธและวันเสาร์-อาทิตย์",
      "1-0006": "ทุกวันพุธและวันเสาร์-อาทิตย์",
      "1-0009": "วันจันถึงวันศุกร์",
      "1-0010": "วันจันถึงวันศุกร์",
      "1-0011": "ทุก5นาที",
      "1-0012": "วันจันถึงวันศุกร์",
      "1-0013": "วันจันถึงวันศุกร์",
      "1-0014": "วันจันทร์และวันเสาร์",
      "1-0015": "สันพุธและวันเสาร์",
      "1-0016": "วันพุธและวันอาทิตย์",
      "1-0017": "ทุก1นาที",
      "1-0018": "ทุกโมง",
      "2-0002": "วันพุธและวันอาทิตย์",
      "2-0003": "ทุก30วิ",
      "2-0004": "ทุก5นาที",
      "2-0005": "ทุกสามนาที",
      "2-0006": "วันอังคารและวันเสาร์",
      "2-0007": "ทุก1นาที",
      "2-0008": "ทุก1นาที"
    },
    "gameRules": {
      "1-0001": "หวยไทยจะออกทุกวันที่1และ16ของเดือน ปิดรับการเดิมพันเวลา15:20น. ของวันหวยออก และเงินรางวัลจะเข้าบัญชีหลังหวยออก30นาที และสามารถแทงหวยงวดทัดไปได้ก่อนวันหวยออก11วัน",
      "1-0002": "เวียดนาม ทุกวันเวลาปิดเข้าร่วมกิจกรรม เวลา18:00 (UTC+7) นาฬิกา เงินอั่งเปาจะเเจกหลัง 30นาที เเละจะเข้ายูสเซอร์อัตโนมัติ เเละทำการเล่นงวดต่อไปได้ทันที",
      "1-0003": "หวยลาว ทุกวันจันทร์ วันพุธและวันศุกร์ เวลาปิดเข้าร่วมกิจกรรม เวลา 20:00 (UTC+7) นาฬิกา. เงินอั่งเปาจะเเจกหลัง 35นาที เเละจะเข้ายูสเซอร์อัตโนมัติ เเละทำการเล่นงวดต่อไปได้ทันที",
      "1-0005": "หวยมาเลเซีย (magnum4D)เปิดทุกวันพุธ เสาร์ อาทิตย์ 19:00（โซนเวลา UTC +8) ปิดรับครึ่งชั่วโมงก่อนเปิดผล เงินอั่งเปาจะเเจกหลัง 2ชั่วโมง  เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0006": "หวยสิงคโปร์ (4D) เปิดทุกวันพุธ เสาร์ อาทิตย์ 18:30（โซนเวลา UTC +8) ปิดรับครึ่งชั่วโมงก่อนเปิดผล เงินอั่งเปาจะเเจกหลัง 1ชั่วโมง5นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0009": "วันจันทร์ถึงวันศุกย์เวลา16:40 (UTC+7:00) เปิดผล ปิดรับเดิมพันครึ่งชั่วโมงก่อนหวยออก และจะเปิดรับเดิมพันของรอบหน้าระหว่างเปิดผล เงินอั่งเปาจะเเจกหลัง 35นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0010": "วันจันทร์ถึงวันศุกย์เวลา15:30 (UTC+5:30) เปิดผล ปิดรับเดิมพันครึ่งชั่วโมงก่อนหวยออก และจะเปิดรับเดิมพันของรอบหน้าระหว่างเปิดผล เงินอั่งเปาจะเเจกหลัง 35นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0011": "Star 3 จะเปิดตลอด24ชั่วโมงยกเว้นช่วงเวลา 08:05-09:00（โซนเวลา UTC+7:00）ความถี่ 5 นาทีต่อ รอบ จะปิดรับเดิมพัน10วิก่อนออกผล เงินอั่งเปาจะเเจกหลัง1นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0012": "วันจันทร์ถึงวันศุกย์เวลา15:30 (UTC+9) เปิดผล ปิดรับเดิมพันครึ่งชั่วโมงก่อนหวยออก และจะเปิดรับเดิมพันของรอบหน้าระหว่างเปิดผล เงินอั่งเปาจะเเจกหลัง 45นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0013": "วันจันทร์ถึงวันศุกย์เวลา15:30 (UTC+9) เปิดผล ปิดรับเดิมพันครึ่งชั่วโมงก่อนหวยออก และจะเปิดรับเดิมพันของรอบหน้าระหว่างเปิดผล เงินอั่งเปาจะเเจกหลัง 45นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0014": "หวยออกทุกวันจันทร์และเสาเวลา16:00 (UTC+7) หวยออกวันล่ะครั้ง ปิดเดิมพันครึ่งชี่วโมงก่อนหวยออก เงินอั่งเปาจะเเจกหลัง 55นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0015": "หวยออกทุกวันพุทธและเสาร์17:00 (UTC+7) วันล่ะครั้ง，ปิดเดิมพันครึ่งชี่วโมงก่อนหวยออก เงินอั่งเปาจะเเจกหลัง 55นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0016": "หวยออกทุกวันพุทธและอาทิตย์17:00 (UTC+7) วันล่ะครั้ง，ปิดเดิมพันครึ่งชี่วโมงก่อนหวยออก เงินอั่งเปาจะเเจกหลัง 55นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "1-0017": "บิทคอยน์จะเปิดตลอด24ชั่วโมงทุกๆ1นาที(โซนเวลา UTC+7:00）ปิดการซื้อขายก่อนเปิด30วิ เงินรางวัลจะแจกเข้ายูสเซอร์อัตโนมัติหลัง1นาที หากคุณคิดว่าราคาบิทคอยน์จะแพงขึ้นกรุณาลง ใหญ่ หากคุณคิดว่าราคาบิทคอยน์จะตกกรุณาลง เล็ก",
      "1-0018": "Yee Kee เดิมพันได้ทุกวัน เปิดรางวัลทุกชั่วโมง หลังเริ่มเกมให้เดิมพันสามารถส่งเลขฟรีให้ระบบสะสมแบบไม่จำกัดจำนวนได้ทุกๆ10วิ หยุดรับเดิมพันก่อนหมดเวลา2นาที แต่ผู้เล่นสามารถส่งเลขจนหมดเวลา เงินรางวัลจะส่งเข้าบัญชีอัตโนมัติ และสามารถเล่นรอบต่อไปได้",
      "2-0002": "หวยปิงปองเม็กซิโก (Melate) จะเปิดทุกวันพุทและวันอาทิตย์ ปิดการขายในเวลา 20:45ของวันที่ออกรางวัล (CDT/CST) รางวัลจะเปิดและจ่ายรางวัลภายในเวลา23:15 ~ 23:45 (CDT/CST) และจะสามารถซื้อของรอบถัดไปในเวลา21:45 (CDT/CST)",
      "2-0003": "หวย12ราศีจะเปิดตลอด24ชั่วโมง（โซนเวลา UTC+7:00）เงินรางวัลจะเเจกหลัง 1นาที เเละจะเข้ายูสเซอร์อัตโนมัติ จะเปิดทุกๆ30วิต่อรอบ สามารถเลือกเลข01~12เดิมพัน หรือสามารถเดิมพันสูง ต่ำ คู่ คี่ ระบบจะสุ่มเลข1~3เลขเป็นรางวัล",
      "2-0004": "PK10 จะเปิดตลอด24ชั่วโมงยกเว้นช่วงเวลา 08:05-09:00（โซนเวลา UTC+7:00）ความถี่ 5 นาทีต่อ รอบ จะปิดรับเดิมพัน10วิก่อนออกผล เงินอั่งเปาจะเเจกหลัง1นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "2-0005": "RG จะเปิดตลอด24ชั่วโมงยกเว้นช่วงเวลา 08:03-09:00（โซนเวลา UTC+7:00）ความถี่ 3 นาทีต่อ รอบ จะปิดรับเดิมพัน10วิก่อนออกผลเงินอั่งเปาจะเเจกหลัง1นาที เเละจะเข้ายูสเซอร์อัตโนมัติ",
      "2-0006": "หวยปิงปองเม็กซิโก (Melate Retro) จะเปิดทุกวันอังคารและวันเสาร์ ปิดการขายในเวลา 20:45ของวันที่ออกรางวัล (CDT/CST) รางวัลจะเปิดและจ่ายรางวัลภายในเวลา23:15 ~ 23:45 (CDT/CST) และจะสามารถซื้อของรอบถัดไปในเวลา21:45 (CDT/CST)",
      "2-0007": "หวยปิงปองวินวิน วินออกตลอด24ชั่วโมง（โซนเวลาUTC+7:00） หวยออกทุก1นาทีต่อรอบ รางวัลจะโอนเข้าบัญชีผู้เล่นหลังหวยออก1นาทีอัตโนมัติ ท่านสามารถเลือกเลข01～12เพื่อทำการเดิมพัน ระบบจะสุ่มออกเลข6เลขเป็นเลขรางวัล",
      "2-0008": "คีโน่เปิดผลตลอด24ชั่วโมง(โซนเวลาUTC+7:00)เปิดทุก1นาที รางวัลจะโอนเข้าบัญชีก่อนเริ่มรอบต่อไป1นาทีโดยอัตโนมัติ. หวยคีโน่จะสุ่มเลข01~80 ออกมา20เลขเป็นผลรางวัล"
    },
    "error": {
      "common": {
        "forbidden": "คุณไม่มีสิทธิ์ในการเข้าถึง",
        "system": {
          "busy": "ระบบกำลังยุ่ง"
        }
      }
    }
  }
}