{
  "common": {
    "language": "Language",
    "lang": {
      "en": "English",
      "th": "ภาษาไทย",
      "zhHans": "简体中文"
    },
    "menuList": {
      "about": "About",
      "gameTypes": "Game Types",
      "ourService": "Our Service",
      "partnership": "Partnership",
      "contact": "Contact Us"
    },
    "welcome": "Welcome to ",
    "slogan": "Easy Play, Mega Win",
    "scroll": "scroll",
    "etc": "etc",
    "playGame": "Play Game",
    "detail": "Detail Info",
    "return": "Return",
    "similarGame": "Similar Game",
    "about": {
      "title": {
        "service": "24-Hour"
      },
      "description": {
        "gameTypes": "Variety Of Game Types",
        "service": "24/7 Customer Service",
        "currency": "Currency Support",
        "device": "Full Device Support",
        "language": "Multilingual"
      }
    },
    "gameType": {
      "hot": "Hot",
      "frequent": "Frequent",
      "graph": "Graph",
      "liveStream": "Live Stream",
      "lottery": "Lottery",
      "rng": "RNG",
      "ball": "Ball",
      "stock": "Stock",
      "description": {
        "graph": "Instant trend",
        "liveStream": "Live stream results",
        "lottery": "Multiple popular lottery",
        "rng": "24Hours ongoing",
        "ball": "Well-known ball games",
        "stock": "Multinational famous stock index"
      }
    },
    "ourService": {
      "title": {
        "api": "API Concat",
        "admin": "Admin Management System",
        "risk": "Instant Risk Monitor"
      },
      "description": {
        "api-1": "Multi-wallet: One-time Intergration",
        "admin-1": "Account Management",
        "admin-2": "Multi-report Management",
        "admin-3": "24/7 Customer Service",
        "risk-1": "Hot Number Setting",
        "risk-2": "Payout Downgrade Automatically",
        "risk-3": "Notification When Turnover Reached"
      }
    },
    "gameInfo": {
      "awardPeriod": "Award Period",
      "betType": "Bet Type Count",
      "bundleType": "Bundle Type",
      "maxPayout": "Max Pay Rate",
      "deviceSupport": "Device Support",
      "languageSupport": "Language Support",
      "rule": "Rules"
    },
    "bundleType": {
      "19d": "19D",
      "box": "Box",
      "combo": "Combo"
    },
    "gameName": {
      "1-0001": "Thailand Lotto",
      "1-0002": "Vietnam (Hanoi) Lotto",
      "1-0003": "Laos Lotto",
      "1-0005": "Malaysia (Magnum 4D) Lotto",
      "1-0006": "Singapore (4D) Lotto",
      "1-0009": "Thailand (SET) Stock",
      "1-0010": "India (Sensex) Stock",
      "1-0011": "Star 3",
      "1-0012": "Japan (Nikkei225) Stock",
      "1-0013": "Korea (KTOP30) Stock",
      "1-0014": "Vietnam (HoChiMinh) Lotto",
      "1-0015": "Vietnam (DaNang) Lotto",
      "1-0016": "Vietnam (KhanhHoa) Lotto",
      "1-0017": "Bitcoin",
      "1-0018": "Yee Kee",
      "2-0002": "Mexico (Melate) Ball",
      "2-0003": "Zodiac",
      "2-0004": "PK10",
      "2-0005": "RG",
      "2-0006": "Mexico (Melate Retro) Ball",
      "2-0007": "Win-Win",
      "2-0008": "Keno"
    },
    "awardPeriod": {
      "1-0001": "1st, 16th Every Month",
      "1-0002": "Everyday",
      "1-0003": "Mondays, Wednesdays and Fridays",
      "1-0005": "Wednesdays, Saturdays and Sundays",
      "1-0006": "Wednesdays, Saturdays and Sundays",
      "1-0009": "Monday to Friday",
      "1-0010": "Monday to Friday",
      "1-0011": "Every 5 minutes",
      "1-0012": "Monday to Friday",
      "1-0013": "Monday to Friday",
      "1-0014": "Mondays and Saturdays",
      "1-0015": "Wednesdays and Saturdays",
      "1-0016": "Wednesdays and Sundays",
      "1-0017": "Every 1 minute",
      "1-0018": "Every 1 hour",
      "2-0002": "Wednesdays and Sundays",
      "2-0003": "Every 30 seconds",
      "2-0004": "Every 5 minutes",
      "2-0005": "Every 3 minutes",
      "2-0006": "Tuesdays and Saturdays",
      "2-0007": "Every 1 minute",
      "2-0008": "Every 1 minute"
    },
    "gameRules": {
      "1-0001": "Thailand Lotto draws the prize on the 1st and 16th of every month, last buy-sell at 15:20 (UTC+7) of every lottery day. The prize will be automatically transferred into the player account 30 minutes later of each draw. The next draw will begin eleven days before the draw.",
      "1-0002": "Daily lottery (break on Vietnam national holiday), last buy-sell at 18:00 (UTC+7). The prize will be automatically transferred into the player account 30 minutes later of each draw. And continues the next draw at 19:00 (UTC+7).",
      "1-0003": "Draw the lotto prize “every Monday, Wednesday and Friday of every week” last buy-sell at 20:00 (UTC+7). The prize will be automatically transferred into the player account 35 minutes later of each draw. And continues the next draw at 21:00 (UTC+7).",
      "1-0005": "Malaysia (Magnum 4D) Lotto draws the prize on every Wednesday, Saturday and Sunday 19:00(UTC+8), last buy-sell time is half hour before each draw. The prize will be automatically transferred into the player account 2 hour later of each draw.",
      "1-0006": "Singapore (4D) Lotto draws the prize on every Wednesday, Saturday and Sunday 18:30(UTC+8), last buy-sell time is half hour before each draw. The prize will be automatically transferred into the player account 1 hour 5 minutes later of each draw.",
      "1-0009": "Daily lottery at 16:40(UTC+7:00), last buy-sell time is half an hour before each draw. The prize will be automatically transferred into the player account 35 minutes later of each draw.",
      "1-0010": "Daily lottery at 15:30(UTC+5:30), last buy-sell time is half an hour before each draw. The prize will be automatically transferred into the player account 35 minutes later of each draw.",
      "1-0011": "Star 3 draws every 5 minutes except 08:05-09:00 (UTC+7:00), last buy-sell time is 10 seconds before each draw. The prize will be automatically transferred into the player account 1 minute later of each draw.",
      "1-0012": "Monday to Friday at 15:30 (UTC+9), last buy-sell time is 30 minutes before each draw. The prize will be automatically transferred into the player account 45 minutes later of each draw.",
      "1-0013": "Monday to Friday at 15:30 (UTC+9), once per day, last buy-sell time is half hour before each draw. The prize will be automatically transferred into the player account 45 minutes later of each draw.",
      "1-0014": "Monday and Saturday at 16:00 (UTC+7), once per day, last buy-sell time is half hour before each draw. The prize will be automatically transferred into the player account 55 minutes later of each draw.",
      "1-0015": "Wednesday and Saturday at 17:00 (UTC+7), once per day, last buy-sell time is half hour before each draw. The prize will be automatically transferred into the player account 55 minutes later of each draw.",
      "1-0016": "Wednesday and Sunday at 17:00 (UTC+7), once per day, last buy-sell time is half hour before each draw. The prize will be automatically transferred into the player account 55 minutes later of each draw.",
      "1-0017": "Bitcoin draws every 1 minute (UTC+7:00). The prize will be automatically transferred into the player account after each draw. Player may predict the stock price will be higher than or below the current position.",
      "1-0018": "Yee Kee is a whole year game, draws hourly. Player can sent guess number for free as many times as you want, but at least 10 seconds between each time. Last buy-sell is 2 minutes before each draw, during the 2 minutes player can still sent guess number until current draw closed. The prize will be automatically transferred into the player account after each draw. And continues playing the next draw immediately.",
      "2-0002": "Mexico (Melate) Ball draws the prize on Wednesday and Sunday every week, last buy-sell at 20:45 (CDT/CST) of every lottery day. The prize will be automatically transferred into the player account around 23:15 ~ 23:45 (CDT/CST). And continues playing the next draw the at 21:45 (CDT/CST).",
      "2-0003": "Zodiac draws every 30 seconds (UTC+7:00). The prize will be automatically transferred into the player account 1 minute later of each draw. Player may pick number(s) from 01-12, or predict number(s) to be Big, Small, Odd or Even. System will draw 1 to 3 number(s) randomly as final result.",
      "2-0004": "PK10 draws every 5 minutes except 08:05-09:00 (UTC+7:00), last buy-sell time is 10 seconds before each draw. The prize will be automatically transferred into the player account 1 minute later of each draw.",
      "2-0005": "RG draws every 3 minutes except 08:03-09:00 (UTC+7:00), last buy-sell time is 10 seconds before each draw. The prize will be automatically transferred into the player account 1 minute later of each draw.",
      "2-0006": "Mexico (Melate Retro) Ball draws the prize on Tuesday and Saturday every week, last buy-sell at 20:45 (CDT/CST) of every lottery day. The prize will be automatically transferred into the player account around 23:15 ~ 23:45 (CDT/CST). And continues playing the next draw the at 21:45 (CDT/CST).",
      "2-0007": "Win-Win draws every 1 minute (UTC+7:00). The prize will be automatically transferred into the player account 1 minute later of each draw. Player may pick number(s) from 01-12. System will draw 6 numbers as final result.",
      "2-0008": "Keno draws every 1 minute (UTC+7:00). The prize will be automatically transferred into the player account after each draw. System will pick 20 numbers randomly from 01 to 80"
    },
    "error": {
      "common": {
        "forbidden": "Access forbidden",
        "system": {
          "busy": "System Busy"
        }
      }
    }
  }
}